// Average walking speed in kilometers per hour
const WALKING_SPEED_KMH = 4.8;

// Calculate distance between two points using Haversine formula
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  // Validate inputs
  if (!lat1 || !lon1 || !lat2 || !lon2) {
    console.error('Invalid coordinates:', { lat1, lon1, lat2, lon2 });
    throw new Error('Invalid coordinates provided');
  }

  const R = 6371; // Earth's radius in kilometers
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  
  const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
           Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * 
           Math.sin(dLon/2) * Math.sin(dLon/2);
  
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  const distance = R * c;
  
  return distance; // Distance in kilometers
};

const toRad = (degrees) => {
  return degrees * (Math.PI/180);
};

// You'll need to get an API key from https://openrouteservice.org/
const ORS_API_KEY = process.env.REACT_APP_ORS_API_KEY;
const ORS_API_URL = 'https://api.openrouteservice.org/v2/directions/foot-walking';

export const estimateWalkingTime = async (origin, destination) => {
  console.log('Estimating walking time with:', { origin, destination });

  // Handle both uppercase and lowercase property names
  const originLat = origin.latitude || origin.Latitude;
  const originLon = origin.longitude || origin.Longitude;
  const destLat = destination.latitude || destination.Latitude;
  const destLon = destination.longitude || destination.Longitude;

  try {
    // Validate we have all required coordinates
    if (!originLat || !originLon || !destLat || !destLon) {
      console.error('Missing coordinates:', {
        origin: { lat: originLat, lon: originLon },
        destination: { lat: destLat, lon: destLon }
      });
      throw new Error('Missing coordinates');
    }

    if (!ORS_API_KEY) {
      console.error('OpenRouteService API key not found');
      return fallbackEstimateWalkingTime(originLat, originLon, destLat, destLon);
    }

    console.log('Making OpenRouteService API call with coordinates:', {
      origin: [originLon, originLat],
      destination: [destLon, destLat]
    });

    const response = await fetch(ORS_API_URL, {
      method: 'POST',
      headers: {
        'Authorization': ORS_API_KEY,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        coordinates: [
          [originLon, originLat],
          [destLon, destLat]
        ]
      })
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('OpenRouteService API error:', {
        status: response.status,
        error: errorText
      });
      return fallbackEstimateWalkingTime(originLat, originLon, destLat, destLon);
    }

    const data = await response.json();
    console.log('OpenRouteService API response:', data);

    if (data && data.features && data.features[0] && data.features[0].properties) {
      const durationSeconds = data.features[0].properties.segments[0].duration;
      const durationMinutes = Math.round(durationSeconds / 60);

      console.log('Walk time calculation result:', {
        durationSeconds,
        durationMinutes,
        source: 'OpenRouteService'
      });

      return durationMinutes;
    } else {
      console.error('Unexpected API response format:', data);
      return fallbackEstimateWalkingTime(originLat, originLon, destLat, destLon);
    }

  } catch (error) {
    console.error('Walk time estimation error:', error);
    return fallbackEstimateWalkingTime(originLat, originLon, destLat, destLon);
  }
};

// Fallback method using simple distance calculation
const fallbackEstimateWalkingTime = (originLat, originLon, destLat, destLon) => {
  const WALKING_SPEED_KMH = 4.8;
  const distance = calculateDistance(originLat, originLon, destLat, destLon);
  // Add 20% to account for non-direct routes and crossings
  const timeInMinutes = Math.round((distance / WALKING_SPEED_KMH) * 60 * 1.2);
  
  console.log('Walk time calculation result:', {
    distance,
    timeInMinutes,
    source: 'Fallback'
  });

  return timeInMinutes;
};
