// src/EditTimetable.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from './axiosConfig';
import TimetableForm from './components/TimetableForm';
import { toast } from 'react-toastify';
import Sidebar from './components/Sidebar';
import './Home.css';

function EditTimetable() {
  const [timetable, setTimetable] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchTimetable = async () => {
      try {
        const response = await axiosInstance.get(`/api/timetable/${id}`);
        if (response.data && response.data.timetable) {
          const { timetable, busStopName, walkTime, university } = response.data;
          setTimetable({
            ...timetable,
            busStopName,
            walkTime,
            university
          });
        } else {
          throw new Error('Timetable not found');
        }
      } catch (err) {
        toast.error(err.response?.data?.msg || 'Failed to fetch timetable');
        setError('Failed to fetch timetable details.');
        console.error('Error fetching timetable:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchTimetable();
  }, [id]);

  const handleSubmit = async (formData) => {
    try {
      await axiosInstance.put(`/api/timetable/${id}`, formData);
      toast.success(`Timetable "${formData.name || 'Untitled'}" updated successfully!`);
      navigate('/timetable');
    } catch (error) {
      toast.error(error.response?.data?.msg || 'Failed to update timetable');
      throw error;
    }
  };

  const handleCancel = () => {
    toast.info('Cancelled editing timetable');
    navigate('/timetable');
  };

  if (loading) {
    return (
      <div className="home-layout">
        <Sidebar />
        <div className="main-content">
          <div className="loading">Loading...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="home-layout">
        <Sidebar />
        <div className="main-content">
          <div className="error-message">{error}</div>
          <button className="btn secondary" onClick={() => navigate('/timetable')}>
            Back to Timetables
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="home-layout">
      <Sidebar />
      <div className="main-content">
        {timetable && (
          <TimetableForm
            initialData={timetable}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            isEditing={true}
          />
        )}
      </div>
    </div>
  );
}

export default EditTimetable;