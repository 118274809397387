import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from './axiosConfig';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { 
  IoTimeOutline,
  IoNotifications, 
  IoNotificationsOff,
  IoAdd, 
  IoList,
  IoPencil,
  IoSchool,
  IoBusSharp,
  IoCalendarClearOutline,
  IoLocationOutline,
  IoWalkOutline,
  IoAlarmOutline,
  IoTodayOutline,
  IoInformationCircleOutline
} from 'react-icons/io5';
import './Home.css';
import Sidebar from './components/Sidebar';
import { AuthContext } from './contexts/AuthContext';

function LoadingScreen() {
  return (
    <div className="loader-container">
      <div className="loader">
        <div className="loader-circle"></div>
        <div className="loader-circle"></div>
        <div className="loader-circle"></div>
      </div>
      <div className="loader-text">Loading your schedule...</div>
    </div>
  );
}

function Home() {
  const { user, toggleNotifications } = useContext(AuthContext);
  const [currentTimetable, setCurrentTimetable] = useState(null);
  const [loading, setLoading] = useState(true);
  const [nextBusTime, setNextBusTime] = useState(null);
  const [nextBusData, setNextBusData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching current timetable...');
        const timetablesRes = await axiosInstance.get('/api/timetable/current');
        console.log('Timetable response:', timetablesRes.data);
        
        if (timetablesRes.data) {
          const { timetable, busStopName, walkTime } = timetablesRes.data;
          console.log('Bus stop info:', { busStopName, walkTime });
          setCurrentTimetable({ ...timetable, busStopName, walkTime });
          
          // Fetch next bus time if we have a bus stop name
          if (busStopName) {
            console.log('Fetching bus times for stop:', busStopName);
            const busRes = await axiosInstance.get(`/api/bus/times`, {
              params: { from: busStopName }
            });
            console.log('Bus times response:', busRes.data);
            
            if (busRes.data && busRes.data.departures && busRes.data.departures.length > 0) {
              const nextDeparture = busRes.data.departures[0];
              const expectedTime = new Date(nextDeparture.expected);
              const minutesUntil = Math.round((expectedTime - new Date()) / 60000);
              
              setNextBusTime(minutesUntil <= 0 ? 'Due' : `${minutesUntil} mins`);
              setNextBusData({
                service: nextDeparture.service,
                destination: nextDeparture.destination,
                expectedTime: expectedTime
              });
            } else {
              setNextBusTime('No buses available');
              setNextBusData(null);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response?.status === 401) {
          navigate('/login');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // Refresh bus times every minute
    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
  }, [navigate]);

  const getNextLecture = () => {
    if (!currentTimetable?.lectures.length) return null;
    
    const now = new Date();
    const currentDay = now.toLocaleString('en-US', { weekday: 'long' });
    const currentTime = now.toLocaleTimeString('en-US', { hour12: false });
    
    // Filter lectures for today and upcoming
    const todayLectures = currentTimetable.lectures
      .filter(lecture => {
        if (lecture.day !== currentDay) return false;
        const lectureTime = lecture.time;
        return lectureTime > currentTime;
      })
      .sort((a, b) => {
        const timeA = new Date(`1970/01/01 ${a.time}`);
        const timeB = new Date(`1970/01/01 ${b.time}`);
        return timeA - timeB;
      });

    // If no more lectures today, get the next day's first lecture
    if (todayLectures.length === 0) {
      const daysOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
      const currentDayIndex = daysOrder.indexOf(currentDay);
      
      // Look for lectures in the following days
      for (let i = 1; i < daysOrder.length; i++) {
        const nextDayIndex = (currentDayIndex + i) % daysOrder.length;
        const nextDayLectures = currentTimetable.lectures
          .filter(lecture => lecture.day === daysOrder[nextDayIndex])
          .sort((a, b) => {
            const timeA = new Date(`1970/01/01 ${a.time}`);
            const timeB = new Date(`1970/01/01 ${b.time}`);
            return timeA - timeB;
          });
          
        if (nextDayLectures.length > 0) {
          return { ...nextDayLectures[0], isNextDay: true };
        }
      }
      
      // If no lectures found in the following days, return the first lecture of the week
      const allLectures = currentTimetable.lectures
        .sort((a, b) => {
          const dayDiff = daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day);
          if (dayDiff === 0) {
            const timeA = new Date(`1970/01/01 ${a.time}`);
            const timeB = new Date(`1970/01/01 ${b.time}`);
            return timeA - timeB;
          }
          return dayDiff;
        });
        
      return { ...allLectures[0], isNextWeek: true };
    }

    return todayLectures[0];
  };

  const getTodaySchedule = () => {
    if (!currentTimetable?.lectures.length) return [];
    
    const now = new Date();
    const currentDay = now.toLocaleString('en-US', { weekday: 'long' });
    
    return currentTimetable.lectures
      .filter(lecture => lecture.day === currentDay)
      .sort((a, b) => {
        const timeA = new Date(`1970/01/01 ${a.time}`);
        const timeB = new Date(`1970/01/01 ${b.time}`);
        return timeA - timeB;
      });
  };

  return (
    <div className="home-container">
      <Sidebar />
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="main-content">
          <div className="content-wrapper">
            <header className="header-section glass-card fade-up">
              <div className="header-content">
                <div className="welcome-group">
                  <h1 className="welcome-text">Welcome back, {user?.name}</h1>
                  <p className="subtitle">Here's your schedule for today</p>
                </div>
                <div className="notification-toggle">
                  <button 
                    className={`toggle-button ${user?.notifications ? 'active' : ''}`}
                    onClick={async () => {
                      const success = await toggleNotifications();
                      if (success) {
                        toast.success(`Notifications ${!user?.notifications ? 'enabled' : 'disabled'}`, {
                          autoClose: 1500
                        });
                      } else {
                        toast.error('Failed to update notifications', {
                          autoClose: 1500
                        });
                      }
                    }}
                  >
                    {user?.notifications ? (
                      <>
                        <IoNotifications className="icon" />
                        <span className="status">On</span>
                      </>
                    ) : (
                      <>
                        <IoNotificationsOff className="icon" />
                        <span className="status">Off</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </header>

            <div className="dashboard-grid">
              <section className="quick-actions-grid fade-up">
                <div className="glass-card next-bus-card">
                  <div className="card-icon-header">
                    <IoBusSharp className="card-icon" />
                    <h3>Next Bus to Uni</h3>
                  </div>
                  {currentTimetable ? (
                    <>
                      <div className="time-display">
                        <span className="time">{nextBusTime}</span>
                        {nextBusData && (
                          <>
                            <span className="detail">
                              {nextBusData.service} to {nextBusData.destination}
                            </span>
                          </>
                        )}
                      </div>
                      <div className="info-row">
                        <IoLocationOutline className="info-icon" />
                        <span>Your Bus Stop: {currentTimetable.busStopName || 'Not set'}</span>
                      </div>
                      <div className="info-row">
                        <IoWalkOutline className="info-icon" />
                        <span>Walking Distance: {currentTimetable.walkTime || 0} mins</span>
                      </div>
                    </>
                  ) : (
                    <div className="no-data-message">
                      No bus stop information available
                    </div>
                  )}
                </div>

                <div className="glass-card next-lecture-card">
                  <div className="card-icon-header">
                    <IoSchool className="card-icon" />
                    <h3>Next Lecture</h3>
                  </div>
                  {getNextLecture() && (
                    <>
                      <div className="time-display">
                        <span className="time">{getNextLecture().time}</span>
                        <span className="detail">
                          {getNextLecture().isNextDay 
                            ? `Tomorrow, ${getNextLecture().day}`
                            : getNextLecture().isNextWeek
                            ? `Next ${getNextLecture().day}`
                            : getNextLecture().day}
                        </span>
                      </div>
                      <div className="info-row">
                        <IoLocationOutline className="info-icon" />
                        <span>{getNextLecture().campus}</span>
                      </div>
                    </>
                  )}
                </div>

                <div className="glass-card today-card">
                  <div className="card-icon-header">
                    <IoTodayOutline className="card-icon" />
                    <h3>Today's Schedule</h3>
                  </div>
                  <div className="today-schedule">
                    {getTodaySchedule().slice(0, 3).map((lecture, index) => (
                      <div key={index} className="schedule-row">
                        <IoAlarmOutline className="time-icon" />
                        <span className="time">{lecture.time}</span>
                        <span className="location">{lecture.campus}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </section>

              <section className="glass-card weekly-schedule-card fade-up">
                <div className="card-header">
                  <div className="header-with-icon">
                    <IoCalendarClearOutline className="section-icon" />
                    <h3>Weekly Schedule</h3>
                  </div>
                  <div className="action-buttons">
                    <button 
                      className="action-button edit" 
                      onClick={() => navigate(`/timetable/edit/${currentTimetable._id}`)}
                      aria-label="Edit timetable"
                    >
                      <IoPencil className="icon" aria-hidden="true" />
                      <span className="label">Edit</span>
                    </button>
                    <button 
                      className="action-button view" 
                      onClick={() => navigate('/timetable')}
                      aria-label="View all timetables"
                    >
                      <IoList className="icon" aria-hidden="true" />
                      <span className="label">View All</span>
                    </button>
                  </div>
                </div>
                
                <div className="schedule-list">
                  {currentTimetable.lectures
                    .sort((a, b) => {
                      const dayOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
                      const dayDiff = dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day);
                      if (dayDiff === 0) {
                        const timeA = new Date(`1970/01/01 ${a.time}`);
                        const timeB = new Date(`1970/01/01 ${b.time}`);
                        return timeA - timeB;
                      }
                      return dayDiff;
                    })
                    .map((lecture, idx) => (
                      <div 
                        key={idx} 
                        className="schedule-item glass-effect fade-up"
                        role="article"
                        aria-label={`${lecture.day} lecture at ${lecture.time} in ${lecture.campus}`}
                      >
                        <div className="day-badge">{lecture.day}</div>
                        <div className="lecture-details">
                          <div className="time-block">
                            <IoTimeOutline className="time-icon" aria-hidden="true" />
                            <span className="time">{lecture.time}</span>
                          </div>
                          <div className="location-block">
                            <IoLocationOutline className="location-icon" aria-hidden="true" />
                            <span className="campus">{lecture.campus}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;