import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiUser, FiMail, FiPhone, FiChevronRight } from 'react-icons/fi';
import axiosInstance from './utils/axiosInstance';
import Sidebar from './components/Sidebar';
import { toast } from 'react-toastify';
import './styles/Profile.css';

const Profile = () => {
  const [user, setUser] = useState({
    name: '',
    email: '',
    phone: '',
    settings: { notifications: false, theme: 'light' }
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axiosInstance.get('/api/user/profile');
        console.log('Profile response:', response.data); // Debug log
        
        if (response.data && response.data.email) {
          setUser(response.data);
        } else {
          console.warn('Invalid user data received:', response.data);
          toast.error('Failed to load profile data');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        if (error.response?.status === 401) {
          navigate('/login');
        } else {
          toast.error('Failed to load profile data');
        }
      }
    };

    fetchUserProfile();
  }, [navigate]);

  const getInitials = () => {
    const name = user?.name || '';
    if (!name) return 'U';
    const nameParts = name.split(' ');
    return nameParts.map(part => part.charAt(0)).join('').toUpperCase() || 'U';
  };

  const handleEditProfile = () => {
    navigate('/profile/edit');
  };

  const ProfileItem = ({ icon: Icon, label, value }) => (
    <div className="profile-item">
      <div className="item-content">
        <div className="item-icon">
          <Icon />
        </div>
        <div className="item-details">
          <div className="item-label">{label}</div>
          <div className="item-value">{value || 'Not set'}</div>
        </div>
      </div>
      <FiChevronRight className="chevron-icon" />
    </div>
  );

  return (
    <div className="layout">
      <Sidebar />
      <div className="main-content">
        <div className="profile-container">
          <div className="profile-group">
            <div className="profile-avatar">
              <div className="avatar-circle">
                {getInitials()}
              </div>
              <div className="avatar-info">
                <h2>{user.name || 'User'}</h2>
                <p>Personal Account</p>
              </div>
            </div>
            <button className="edit-profile-btn" onClick={handleEditProfile}>
              Edit Profile
            </button>
          </div>

          <div className="profile-group">
            <div className="section-title">Personal Information</div>
            <ProfileItem
              icon={FiUser}
              label="Name"
              value={user.name || 'Not set'}
            />
            <ProfileItem
              icon={FiMail}
              label="Email"
              value={user.email}
            />
            <ProfileItem
              icon={FiPhone}
              label="Phone"
              value={user.phone || 'Add phone number'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;