import React from 'react';
import ModernLandingPage from './ModernLandingPage';
import './LandingPage.css';

function LandingPage() {
  return (
    <div className="landing-container">
      <ModernLandingPage />
    </div>
  );
}

export default LandingPage;
