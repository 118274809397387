// src/AddTimetable.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from './axiosConfig';
import TimetableForm from './components/TimetableForm';
import Sidebar from './components/Sidebar';
import './Home.css';

const AddTimetable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || 'timetable';
  const [existingTimetables, setExistingTimetables] = useState(null);

  useEffect(() => {
    // Check if user has any existing timetables
    const checkExistingTimetables = async () => {
      try {
        const response = await axiosInstance.get('/api/timetable');
        setExistingTimetables(response.data.timetables);
      } catch (error) {
        console.error('Error checking existing timetables:', error);
      }
    };

    checkExistingTimetables();
  }, []);

  const handleSubmit = async (formData) => {
    try {
      // Make sure we're sending the name as provided in the form
      const response = await axiosInstance.post('/api/timetable', {
        ...formData,
        name: formData.name.trim() || 'Untitled Timetable',
        // setAsCurrent is not needed as backend will handle this automatically
      });

      const successMessage = existingTimetables?.length === 0 
        ? `Timetable "${formData.name.trim() || 'Untitled Timetable'}" created and set as current timetable!`
        : `Timetable "${formData.name.trim() || 'Untitled Timetable'}" created successfully!`;
      
      toast.success(successMessage);
      navigate('/timetable');
    } catch (error) {
      toast.error(error.response?.data?.msg || 'Failed to create timetable');
      throw error;
    }
  };

  const handleCancel = () => {
    toast.info('Cancelled adding new timetable');
    navigate(from === 'home' ? '/home' : '/timetable');
  };

  return (
    <div className="home-layout">
      <Sidebar />
      <div className="main-content">
        <div className="container">
          <TimetableForm onSubmit={handleSubmit} onCancel={handleCancel} />
        </div>
      </div>
    </div>
  );
};

export default AddTimetable;