import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);

  console.log('ProtectedRoute: isAuthenticated =', isAuthenticated);
  console.log('ProtectedRoute: loading =', loading);

  if (loading) {
    console.log('ProtectedRoute: Still loading, showing loader...');
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    console.log('ProtectedRoute: Not authenticated, redirecting to login...');
    return <Navigate to="/" />;
  }

  console.log('ProtectedRoute: Authenticated, rendering children...');
  return children;
};

export default ProtectedRoute;
