// src/App.js
import React, { useContext } from 'react';
import { HashRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext, AuthProvider } from './contexts/AuthContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { AnimatePresence } from 'framer-motion';
import PageTransition from './components/PageTransition';
import './styles/global.css';

import Login from './Login';
import Register from './Register';
import Home from './Home';
import Settings from './Settings';
import Profile from './Profile';
import EditProfile from './EditProfile';
import Timetable from './Timetable';
import EditTimetable from './EditTimetable';
import AddTimetable from './AddTimetable';
import ProtectedRoute from './components/ProtectedRoute';
import ErrorBoundary from './components/ErrorBoundary';
import NotFound from './components/NotFound';
import LandingPage from './LandingPage';

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <SidebarProvider>
          <Router>
            <AppContent />
            <ToastContainer 
              position="top-right"
              autoClose={1500}
              limit={3}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover={false}
              theme="light"
            />
          </Router>
        </SidebarProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

function AppContent() {
  const location = useLocation();
  
  return (
    <div className="App">
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          {/* Landing Page at root */}
          <Route
            path="/"
            element={
              <PageTransition>
                <AuthStatusWrapper />
              </PageTransition>
            }
          />

          <Route 
            path="/login" 
            element={
              <PageTransition>
                <Login />
              </PageTransition>
            } 
          />
          
          <Route 
            path="/register" 
            element={
              <PageTransition>
                <Register />
              </PageTransition>
            } 
          />

          {/* Protected routes with sidebar - no transition */}
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <ErrorBoundary>
                  <Home />
                </ErrorBoundary>
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile/edit"
            element={
              <ProtectedRoute>
                <EditProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/timetable"
            element={
              <ProtectedRoute>
                <Timetable />
              </ProtectedRoute>
            }
          />
          <Route
            path="/timetable/edit/:id"
            element={
              <ProtectedRoute>
                <EditTimetable />
              </ProtectedRoute>
            }
          />
          <Route
            path="/timetable/add"
            element={
              <ProtectedRoute>
                <AddTimetable />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

// This component checks auth status:
// If authenticated -> redirect to /home
// If not -> show LandingPage at "/"
function AuthStatusWrapper() {
  const { user } = useContext(AuthContext);
  
  if (user) {
    return <Navigate to="/home" />;
  }
  
  return <LandingPage />;
}

export default App;