import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axiosInstance from './axiosConfig';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { FaEye, FaEyeSlash, FaUser, FaEnvelope, FaLock, FaFingerprint, FaArrowLeft } from 'react-icons/fa';
import './Register.css';
// Removed the import of ModernBackground.css for now:
// import './styles/ModernBackground.css';
import './styles/PageTransition.css';
import { toast } from 'react-toastify';
import { AuthContext } from './contexts/AuthContext';

function Register() {
  const navigate = useNavigate();
  const { checkAuth } = useContext(AuthContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState('');
  const [phoneValid, setPhoneValid] = useState(true);
  const [error, setError] = useState('');
  const [activeField, setActiveField] = useState('');
  const [passkeysSupported, setPasskeysSupported] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  useEffect(() => {
    checkPasskeySupport();
  }, []);

  const checkPasskeySupport = async () => {
    try {
      const available = await window.PublicKeyCredential?.isUserVerifyingPlatformAuthenticatorAvailable();
      setPasskeysSupported(available);
    } catch (error) {
      console.error('Error checking passkey support:', error);
    }
  };

  const handleAddPasskey = async () => {
    try {
      const { data: options } = await axiosInstance.post('/api/auth/passkey/register-options');
      
      const credential = await navigator.credentials.create({
        publicKey: {
          ...options,
          challenge: Uint8Array.from(atob(options.challenge), c => c.charCodeAt(0)),
          user: {
            ...options.user,
            id: Uint8Array.from(options.user.id, c => c.charCodeAt(0))
          }
        }
      });

      await axiosInstance.post('/api/auth/passkey/register-verify', {
        credential: {
          id: credential.id,
          type: credential.type,
          rawId: btoa(String.fromCharCode(...new Uint8Array(credential.rawId))),
          response: {
            clientDataJSON: btoa(String.fromCharCode(...new Uint8Array(credential.response.clientDataJSON))),
            attestationObject: btoa(String.fromCharCode(...new Uint8Array(credential.response.attestationObject)))
          }
        }
      });
    } catch (error) {
      console.error('Error adding passkey:', error);
      // Don't throw the error, just show a toast
      toast.warn('Passkey setup can be completed later in settings');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setError('');
    
    // Validate fields
    const errors = {};
    if (!email) errors.email = 'Please fill in this field';
    if (!password || password.length < 6) errors.password = 'Password must be at least 6 characters';
    if (!phone || !phoneValid) errors.phone = 'Please enter a valid phone number';
    
    setFieldErrors(errors);
    
    if (Object.keys(errors).length > 0) {
      return;
    }

    setIsLoading(true);

    try {
      // Register the user
      const { data: { token } } = await axiosInstance.post('/api/auth/register', {
        name,
        email,
        password,
        phone,
      });
      
      // Set the token in localStorage and axios headers
      localStorage.setItem('token', token);
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      // Update authentication state first
      await checkAuth();

      // Try to add passkey if supported, but don't block on failure
      if (passkeysSupported) {
        await handleAddPasskey();
      }
      
      // Show success message
      toast('Account created successfully! Welcome to Two Stopper!');
      
      // Navigate to home
      navigate('/home');
    } catch (err) {
      setError(err.response?.data?.msg || 'An unexpected error occurred. Please try again.');
      toast(err.response?.data?.msg || 'Failed to create account');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`register-container ${isLoading ? 'loading' : ''}`}>
      <div className="register-box">
        <button 
          className="back-button" 
          onClick={() => navigate('/')}
          aria-label="Back to landing page"
        >
          <FaArrowLeft />
        </button>
        <form onSubmit={handleSubmit} className={`auth-form fade-scale-enter-active ${isSubmitted ? 'form-submitted' : ''}`} noValidate>
          
          {/* Logo Section */}
          <div className="logo">
            <img src="/2Slogo.png" alt="Two Stopper Logo" style={{ width: '80px', height: 'auto' }} />
            <h2>Two Stopper</h2>
            <div className="slogan">Join Our Community</div>
          </div>

          {/* Error Message */}
          {error && <div className="error-message">{error}</div>}

          {/* Name Input */}
          <div className={`input-group form-field ${activeField === 'name' ? 'focused' : ''}`}>
            <div className="input-icon">
              <FaUser />
            </div>
            <div className="input-wrapper">
              <input
                type="text"
                placeholder="Enter your name (optional)"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onFocus={() => setActiveField('name')}
                onBlur={() => setActiveField('')}
              />
            </div>
          </div>

          {/* Email Input */}
          <div className={`input-group form-field ${activeField === 'email' ? 'focused' : ''} ${fieldErrors.email ? 'has-error' : ''}`}>
            <div className="input-icon">
              <FaEnvelope />
            </div>
            <div className="input-wrapper">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onFocus={() => setActiveField('email')}
                onBlur={() => setActiveField('')}
                required
              />
              {fieldErrors.email && <div className="validation-message">{fieldErrors.email}</div>}
            </div>
          </div>

          {/* Phone Input */}
          <div className={`input-group form-field ${activeField === 'phone' ? 'focused' : ''} ${fieldErrors.phone ? 'has-error' : ''}`}>
            <div className="input-wrapper phone-input-wrapper">
              <PhoneInput
                country={'gb'}
                onlyCountries={['gb']}
                countryCodeEditable={false}
                disableDropdown={true}
                enableSearch={false}
                placeholder="Enter your UK mobile number"
                value={phone}
                onChange={(value) => {
                  console.log('Phone input value:', value);
                  setPhone(value);
                  // More lenient UK mobile number validation
                  // Accepts numbers starting with 44 followed by 7 and 9 more digits
                  // Also accepts numbers starting with 7 directly (for UK format without country code)
                  const isValidWithCountryCode = /^44[7][0-9]{9}$/.test(value);
                  const isValidWithoutCountryCode = /^[7][0-9]{9}$/.test(value);
                  const isValid = isValidWithCountryCode || isValidWithoutCountryCode;
                  console.log('Phone validation result:', { 
                    value, 
                    isValidWithCountryCode, 
                    isValidWithoutCountryCode, 
                    isValid 
                  });
                  setPhoneValid(isValid);
                  if (isValid) {
                    setFieldErrors(prev => ({ ...prev, phone: '' }));
                  } else {
                    setFieldErrors(prev => ({ 
                      ...prev, 
                      phone: 'Please enter a valid UK mobile number starting with 07 or +447'
                    }));
                  }
                }}
                onFocus={() => setActiveField('phone')}
                onBlur={() => {
                  setActiveField('');
                  if (!phoneValid && phone) {
                    setFieldErrors(prev => ({
                      ...prev,
                      phone: 'Please enter a valid UK mobile number starting with 07 or +447'
                    }));
                  }
                }}
                inputClass={`phone-input ${fieldErrors.phone ? 'error' : ''}`}
                specialLabel=""
                inputProps={{
                  required: true,
                  className: fieldErrors.phone ? 'error' : ''
                }}
              />
              {fieldErrors.phone && <div className="validation-message">{fieldErrors.phone}</div>}
            </div>
          </div>

          {/* Password Input */}
          <div className={`input-group password-container form-field ${activeField === 'password' ? 'focused' : ''} ${fieldErrors.password ? 'has-error' : ''}`}>
            <div className="input-icon">
              <FaLock />
            </div>
            <div className="input-wrapper">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => setActiveField('password')}
                onBlur={() => setActiveField('')}
                required
                autoComplete="new-password"
              />
              <button
                type="button"
                className="toggle-password"
                onClick={toggleShowPassword}
                aria-label={showPassword ? 'Hide password' : 'Show password'}
              >
                {showPassword ? <FaEye size={18} opacity={0.6} /> : <FaEyeSlash size={18} opacity={0.6} />}
              </button>
              {fieldErrors.password && <div className="validation-message">{fieldErrors.password}</div>}
            </div>
          </div>

          {/* Submit Button */}
          <div className="form-field">
            <button type="submit" className="auth-button" disabled={isLoading}>
              {isLoading ? 'Creating Account...' : 'Create Account'}
            </button>
          </div>

          {/* Passkey Information */}
          {passkeysSupported && (
            <div className="form-field passkey-info">
              <FaFingerprint />
              <span>Passkey support detected - you'll be able to set it up after registration</span>
            </div>
          )}

          {/* Login Link */}
          <div className="form-field">
            <p className="login-link">
              Already have an account? <Link to="/login" className="link">Login</Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;