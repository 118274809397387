import React, { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaEnvelope, FaLock, FaEye, FaEyeSlash, FaFingerprint, FaArrowLeft } from 'react-icons/fa';
import axiosInstance from './axiosConfig';
import { AuthContext } from './contexts/AuthContext';
import { useSidebar } from './contexts/SidebarContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/LoadingAnimation.css';
import './styles/PageTransition.css';
import './styles/ModernBackground.css';
import './Login.css';

function Login() {
  const navigate = useNavigate();
  const { checkAuth } = useContext(AuthContext);
  const { resetAnimation } = useSidebar();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [activeField, setActiveField] = useState('');
  const [passkeysSupported, setPasskeysSupported] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    const checkPasskeySupport = async () => {
      try {
        if (window.PublicKeyCredential) {
          const available = await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
          setPasskeysSupported(available);
        }
      } catch (error) {
        console.error('Error checking passkey support:', error);
      }
    };
    checkPasskeySupport();
  }, []);

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await axiosInstance.post('/api/auth/login', {
        email,
        password,
      });
      
      if (response.data && response.data.msg === 'Login successful') {
        if (response.data.token) {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('lastLoginEmail', email);
          
          // Start the fade out animation before authentication
          const loginBox = document.querySelector('.login-box');
          const authForm = document.querySelector('.auth-form');
          loginBox.classList.add('fade-out');
          authForm.classList.add('fade-out');
          
          // Wait for animation to start
          await new Promise(resolve => setTimeout(resolve, 50));
          
          // Update authentication in the background
          await checkAuth();
          resetAnimation();
          
          // Navigate after a smooth transition
          setTimeout(() => {
            navigate('/home');
          }, 250);
        }
      } else {
        setError('Invalid credentials');
        setIsLoading(false);
      }
    } catch (err) {
      console.error('Login error:', err.response?.data || err);
      const errorMessage = err.response?.data?.msg || 'Invalid email or password';
      setError(errorMessage);
      toast.error(errorMessage);
      setIsLoading(false);
    }
  };

  const handlePasskeyLogin = async () => {
    try {
      console.log('Starting passkey login');
      const email = emailRef.current?.value;
      if (!email) {
        toast.error('Email is required for passkey login');
        return;
      }

      console.log('Requesting passkey options for email:', email);
      const { data: options } = await axiosInstance.post('/api/auth/passkey/generate-options', { email });
      
      console.log('Received options:', {
        hasChallenge: !!options.challenge,
        numCredentials: options.allowCredentials?.length || 0
      });
      
      if (!options.allowCredentials?.length) {
        console.log('No passkeys found for email');
        toast.info('No passkey found for this email. Please use password login.');
        return;
      }

      const base64urlToBase64 = str => 
        str.replace(/-/g, '+').replace(/_/g, '/') + '='.repeat((4 - str.length % 4) % 4);

      const challenge = Uint8Array.from(
        atob(base64urlToBase64(options.challenge)),
        c => c.charCodeAt(0)
      );

      const allowCredentials = options.allowCredentials.map(cred => ({
        type: 'public-key',
        id: Uint8Array.from(
          atob(base64urlToBase64(cred.id)),
          c => c.charCodeAt(0)
        ),
        transports: cred.transports
      }));

      console.log('Requesting credential from browser');
      const credential = await navigator.credentials.get({
        publicKey: {
          challenge,
          allowCredentials,
          timeout: 60000,
          userVerification: 'preferred',
          rpId: window.location.hostname
        }
      });

      console.log('Received credential:', {
        id: credential.id,
        type: credential.type,
        hasResponse: !!credential.response
      });

      const authData = {
        id: credential.id,
        rawId: Array.from(new Uint8Array(credential.rawId)),
        type: credential.type,
        response: {
          clientDataJSON: Array.from(new Uint8Array(credential.response.clientDataJSON)),
          authenticatorData: Array.from(new Uint8Array(credential.response.authenticatorData)),
          signature: Array.from(new Uint8Array(credential.response.signature)),
          userHandle: credential.response.userHandle ? Array.from(new Uint8Array(credential.response.userHandle)) : null
        }
      };

      console.log('Sending verification request to server');
      const { data: authResult } = await axiosInstance.post('/api/auth/passkey/verify', { 
        credential: authData 
      });
      
      console.log('Received auth result:', { success: !!authResult.token });
      
      if (authResult.token) {
        console.log('Setting auth token and updating auth state');
        localStorage.setItem('token', authResult.token);
        localStorage.setItem('lastUsedEmail', email);
        
        // Update auth context
        await checkAuth();
        
        // Show success animation before navigation
        const loginBox = document.querySelector('.login-box');
        const authForm = document.querySelector('.auth-form');
        loginBox.classList.add('fade-out');
        authForm.classList.add('fade-out');
        
        // Wait for the fade-out animation to complete before navigating
        setTimeout(() => {
          navigate('/home');
        }, 300); // Match the transition duration in CSS
      } else {
        console.log('No token received');
        toast.error('Authentication failed. Please try again.');
      }
    } catch (error) {
      console.error('Passkey login error:', error);
      console.error('Error details:', {
        name: error.name,
        message: error.message,
        response: error.response?.data
      });
      toast.error('Passkey login failed. Please try again or use password.');
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`login-container modern-gradient-bg ${isLoading ? 'loading' : ''}`}>
      <div className="login-box expanded">
        <button 
          className="back-button" 
          onClick={() => navigate('/')}
          aria-label="Back to landing page"
        >
          <FaArrowLeft />
        </button>
        <form onSubmit={handleSubmit} className="auth-form fade-scale-enter-active">
          <div className="logo form-field">
            <img src="/2Slogo.png" alt="Two Stopper Logo" style={{ width: '120px', height: 'auto' }} />
            <h2>Two Stopper</h2>
          </div>
          {error && <div className="error-message">{error}</div>}
          <div className="input-group form-field">
            <div className="input-icon">
              <FaEnvelope />
            </div>
            <div className="input-wrapper">
              <input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                onFocus={() => setActiveField('email')}
                onBlur={() => setActiveField('')}
                ref={emailRef}
                required
                autoComplete="username"
                placeholder="Email"
              />
            </div>
          </div>
          <div className="input-group form-field">
            <div className="input-icon">
              <FaLock />
            </div>
            <div className="input-wrapper password-input">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => setActiveField('password')}
                onBlur={() => setActiveField('')}
                ref={passwordRef}
                required
                autoComplete="current-password"
                placeholder="Password"
              />
              <button
                type="button"
                className="toggle-password"
                onClick={toggleShowPassword}
                disabled={isLoading}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          <div className="form-field">
            <div className="button-group">
              <button type="submit" className="auth-button" disabled={isLoading}>
                {isLoading ? 'Signing in...' : 'Sign In'}
              </button>
              
              {passkeysSupported && (
                <button 
                  type="button" 
                  className="auth-button passkey-button"
                  onClick={handlePasskeyLogin}
                  disabled={isLoading || !email.trim()}
                  title={!email.trim() ? "Please enter your email first" : ""}
                >
                  <FaFingerprint /> Use Passkey
                </button>
              )}
            </div>
          </div>
          <div className="form-field">
            <p className="register-link">
              Don't have an account? <Link to="/register" className="link">Create Account</Link>
            </p>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;