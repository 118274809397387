import React, { useContext, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  IoHomeOutline,
  IoCalendarClearOutline,
  IoSettingsOutline,
  IoPersonOutline,
  IoLogOutOutline,
  IoHome,
  IoCalendarClear,
  IoSettings,
  IoPerson,
  IoLogOut
} from 'react-icons/io5';
import './Sidebar.css';
import { AuthContext } from '../contexts/AuthContext';
import { useSidebar } from '../contexts/SidebarContext';
import axiosInstance from '../axiosConfig';

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useContext(AuthContext);
  const { shouldAnimateSidebar, disableAnimation } = useSidebar();
  const sidebarRef = useRef(null);

  useEffect(() => {
    if (shouldAnimateSidebar && sidebarRef.current) {
      console.log('Setting up sidebar animation');
      sidebarRef.current.style.opacity = "0";
      sidebarRef.current.style.transform = "translateX(-100px)";
      
      // Force a reflow
      void sidebarRef.current.offsetWidth;
      
      // Add animation class
      sidebarRef.current.classList.add('sidebar-animate');
      
      // Disable animation for subsequent renders
      disableAnimation();
    }
  }, [shouldAnimateSidebar, disableAnimation]);

  useEffect(() => {
    console.log('Location changed:', location.pathname);
  }, [location]);

  const navItems = [
    { 
      path: '/home', 
      icon: <IoHomeOutline className="nav-icon" />,
      activeIcon: <IoHome className="nav-icon" />,
      text: 'Home' 
    },
    { 
      path: '/timetable', 
      icon: <IoCalendarClearOutline className="nav-icon" />,
      activeIcon: <IoCalendarClear className="nav-icon" />,
      text: 'Timetable' 
    },
    { 
      path: '/settings', 
      icon: <IoSettingsOutline className="nav-icon" />,
      activeIcon: <IoSettings className="nav-icon" />,
      text: 'Settings' 
    },
    { 
      path: '/profile', 
      icon: <IoPersonOutline className="nav-icon" />,
      activeIcon: <IoPerson className="nav-icon" />,
      text: 'Profile' 
    },
  ];

  const handleLogout = async () => {
    try {
      console.log('Initiating logout process...');
      await axiosInstance.post('/api/auth/logout');
      console.log('Backend logout successful');
      
      localStorage.removeItem('authToken');
      await logout();
      console.log('Local cleanup completed');
      
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Logout failed:', error);
      localStorage.removeItem('authToken');
      await logout();
      navigate('/', { replace: true });
    }
  };

  return (
    <div 
      ref={sidebarRef}
      className="sidebar"
      onAnimationEnd={(e) => {
        if (e.target === sidebarRef.current) {
          console.log('Animation completed:', e.animationName);
        }
      }}
    >
      <div className="sidebar-header">
        <img src="/2Slogo.png" alt="Two Stopper Logo" className="logo-icon" />
        <h2>Two Stopper</h2>
        <p className="slogan">Your journey planner</p>
      </div>
      <nav className="nav-menu">
        {navItems.map((item) => {
          const isActive = location.pathname === item.path;
          return (
            <div
              key={item.path}
              className={`nav-item ${isActive ? 'active' : ''}`}
              onClick={() => navigate(item.path)}
            >
              {isActive ? item.activeIcon : item.icon}
              <span>{item.text}</span>
            </div>
          );
        })}
      </nav>
      <div className="nav-footer">
        <div className="nav-item logout-nav-item" onClick={handleLogout}>
          <IoLogOutOutline className="nav-icon" />
          <span>Logout</span>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;

