import React, { useState, useEffect, useContext } from 'react';
import { FiSettings, FiBell, FiKey, FiDownload, FiLogOut, FiTrash2 } from 'react-icons/fi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './components/Settings.css';
import { AuthContext } from './contexts/AuthContext';
import axiosInstance from './utils/axiosInstance';
import Sidebar from './components/Sidebar';

const Settings = () => {
  const [loading, setLoading] = useState(true);
  const [passkeysSupported, setPasskeysSupported] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isIOS, setIsIOS] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDeletionSuccess, setShowDeletionSuccess] = useState(false);
  const { user, toggleNotifications } = useContext(AuthContext);

  useEffect(() => {
    setPasskeysSupported('credentials' in navigator);
    setIsIOS(/iPad|iPhone|iPod/.test(navigator.userAgent));

    const loadSettings = async () => {
      try {
        const response = await axiosInstance.get('/api/user/settings');
        // Removed setting settings state here
      } catch (error) {
        console.error('Error loading settings:', error);
        toast.error('Failed to load settings', {
          autoClose: 1500
        });
      } finally {
        setLoading(false);
      }
    };

    loadSettings();
  }, []);

  const handleNotificationsToggle = async () => {
    try {
      const success = await toggleNotifications();
      if (success) {
        toast.success(`Notifications ${!user?.notifications ? 'enabled' : 'disabled'}`, {
          autoClose: 1500
        });
      } else {
        toast.error('Failed to update notifications', {
          autoClose: 1500
        });
      }
    } catch (error) {
      console.error('Error updating settings:', error);
      toast.error('Failed to update settings', {
        autoClose: 1500
      });
    }
  };

  const handleTestNotification = async () => {
    try {
      await axiosInstance.post('/api/user/test-notification');
      toast.success('Test notification sent', {
        autoClose: 1500
      });
    } catch (error) {
      console.error('Error sending test notification:', error);
      toast.error('Failed to send test notification', {
        autoClose: 1500
      });
    }
  };

  const handleAddPasskey = async () => {
    if (!passkeysSupported) {
      toast.error('Your browser does not support passkeys', {
        autoClose: 1500
      });
      return;
    }

    try {
      const credentialResponse = await navigator.credentials.create({
        publicKey: {
          challenge: new Uint8Array(32),
          rp: {
            name: 'Two-Stopper',
            id: window.location.hostname,
          },
          user: {
            id: new Uint8Array(16),
            name: user.email,
            displayName: user.name || user.email,
          },
          pubKeyCredParams: [{alg: -7, type: "public-key"}],
          timeout: 60000,
          attestation: "direct"
        }
      });

      await axiosInstance.post('/api/auth/passkey/register', credentialResponse);
      toast.success('Passkey added successfully!', {
        autoClose: 1500
      });
    } catch (error) {
      console.error('Error adding passkey:', error);
      toast.error('Failed to add passkey. Please try again.', {
        autoClose: 1500
      });
    }
  };

  const handleInstallClick = async () => {
    if (isIOS) {
      toast.info('Tap the share button and select "Add to Home Screen"', {
        autoClose: 1500
      });
      return;
    }

    if (!deferredPrompt) {
      toast.info('App is already installed or not installable', {
        autoClose: 1500
      });
      return;
    }

    try {
      await deferredPrompt.prompt();
      setDeferredPrompt(null);
      toast.success('App installed successfully!', {
        autoClose: 1500
      });
    } catch (error) {
      console.error('Error installing app:', error);
      toast.error('Failed to install app', {
        autoClose: 1500
      });
    }
  };

  const handleDeleteAccount = async () => {
    try {
      setLoading(true);
      await axiosInstance.delete('/api/user/delete-account');
      setShowDeletionSuccess(true);
      localStorage.clear();
      // Redirect after 3 seconds
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    } catch (error) {
      console.error('Error deleting account:', error);
      toast.error(error.response?.data?.message || 'Failed to delete account', {
        autoClose: 1500
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      await axiosInstance.post('/api/auth/logout');
      localStorage.clear();
      window.location.href = '/login';
    } catch (error) {
      console.error('Error signing out:', error);
      toast.error('Failed to sign out', {
        autoClose: 1500
      });
    }
  };

  if (showDeletionSuccess) {
    return (
      <div className="deletion-success-container">
        <div className="deletion-success-content">
          <img src="/2Slogo.png" alt="Two Stopper Logo" className="success-logo" />
          <h1>Account Successfully Deleted</h1>
          <p>Thank you for using Two Stopper. Your account has been permanently deleted.</p>
          <p>You will be redirected to the home page in a few seconds...</p>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="settings-layout">
        <Sidebar />
        <div className="main-content">
          <div className="loading">Loading...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="settings-layout">
      <Sidebar />
      <div className="main-content">
        <div className="settings-container">
          <div className="settings-group">
            <h2>Notifications</h2>
            <div className="setting-item">
              <div className="setting-item-content">
                <div className="setting-item-icon">
                  <FiBell />
                </div>
                <div className="item-details">
                  <div className="item-label">Push Notifications</div>
                  <div className="item-value">Get notified about important updates</div>
                </div>
              </div>
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  checked={user?.notifications}
                  onChange={handleNotificationsToggle}
                />
                <span className="toggle-slider"></span>
              </label>
            </div>
            {user?.notifications && (
              <button className="test-notification-btn" onClick={handleTestNotification}>
                Send Test Notification
              </button>
            )}
          </div>

          <div className="settings-group">
            <h2>Security</h2>
            <div className="setting-item" onClick={handleAddPasskey}>
              <div className="setting-item-content">
                <div className="setting-item-icon">
                  <FiKey />
                </div>
                <div className="item-details">
                  <div className="item-label">Passkey Authentication</div>
                  <div className="item-value">Add a passkey for secure sign-in</div>
                </div>
              </div>
              <FiSettings className="chevron-icon" />
            </div>
          </div>

          <div className="settings-group">
            <h2>App</h2>
            <div className="setting-item" onClick={handleInstallClick}>
              <div className="setting-item-content">
                <div className="setting-item-icon">
                  <FiDownload />
                </div>
                <div className="item-details">
                  <div className="item-label">Install App</div>
                  <div className="item-value">Add to Home Screen for quick access</div>
                </div>
              </div>
              <FiSettings className="chevron-icon" />
            </div>
          </div>

          <div className="settings-group">
            <h2>Account</h2>
            <div className="setting-item" onClick={handleSignOut}>
              <div className="setting-item-content">
                <div className="setting-item-icon">
                  <FiLogOut />
                </div>
                <div className="item-details">
                  <div className="item-label">Sign Out</div>
                  <div className="item-value">Sign out of your account</div>
                </div>
              </div>
              <FiSettings className="chevron-icon" />
            </div>
          </div>

          <div className="settings-group danger-zone">
            <h2>Danger Zone</h2>
            <div className="setting-item" onClick={() => setShowDeleteConfirm(true)}>
              <div className="setting-item-content">
                <div className="setting-item-icon">
                  <FiTrash2 />
                </div>
                <div className="item-details">
                  <div className="item-label">Delete Account</div>
                  <div className="item-value">Permanently delete your account</div>
                </div>
              </div>
              <FiSettings className="chevron-icon" />
            </div>
            {showDeleteConfirm && (
              <div className="delete-confirm">
                <p>Are you sure you want to delete your account? This action cannot be undone.</p>
                <div className="delete-buttons">
                  <button className="cancel-button" onClick={() => setShowDeleteConfirm(false)}>
                    Cancel
                  </button>
                  <button className="confirm-delete-button" onClick={handleDeleteAccount}>
                    Delete Account
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;