// src/components/TimetableForm.js

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {
  FaSave,
  FaArrowLeft,
  FaArrowRight,
  FaPlus,
  FaTrash,
  FaLocationArrow,
  FaMapMarkedAlt,
  FaCalculator,
  FaCheck,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../axiosConfig';
import './TimetableForm.css';
import NearbyBusStopsMap from './NearbyBusStopsMap';
import BusStopMap from './BusStopMap';
import { estimateWalkingTime } from '../utils/distanceUtils';

const universityOptions = [
  { value: 'UWE', label: 'UWE', logo: '/uwe-logo.svg' },
  { value: 'UOB', label: 'UOB', logo: '/uob-logo.png' },
];

const campusOptions = {
  UWE: [
    { value: 'Frenchay', label: 'Frenchay' },
    { value: 'Glenside', label: 'Glenside' },
    { value: 'Arnolfini', label: 'Arnolfini' },
    { value: 'Bower Ashton', label: 'Bower Ashton' },
  ],
  UOB: [
    { value: 'City (main)', label: 'City (main)' },
    { value: 'Langford', label: 'Langford' },
  ],
};

const dayOptions = [
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
];

const timeOptions = Array.from({ length: 12 * 4 }, (_, index) => {
  const hours = Math.floor(index / 4) + 8;
  const minutes = (index % 4) * 15;
  const label = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
  return { value: label, label };
});

const TimetableForm = ({ initialData, onSubmit, onCancel }) => {
  const [formData, setFormData] = useState({
    name: initialData?.name || '',
    university: null,
    campuses: [],
    busStop: null,
    walkTime: '',
    lectures: [],
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [nearbyBusStops, setNearbyBusStops] = useState([]);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showMap, setShowMap] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [animatingStep, setAnimatingStep] = useState(false);

  useEffect(() => {
    if (initialData) {
      const loadInitialBusStop = async () => {
        try {
          // Only fetch bus stop if we have a NaptanCode or busStopName
          if (initialData.busStopName) {
            const response = await axiosInstance.get('/api/timetable/bus-stops', {
              params: { search: initialData.busStopName },
            });
            const busStopData = response.data[0]; // Get the first matching bus stop

            const transformedData = {
              ...initialData,
              name: initialData.name || '',
              university: universityOptions.find(
                (opt) => opt.value === initialData.university
              ),
              campuses: campusOptions[initialData.university].filter(
                (campus) => initialData.campuses.includes(campus.value)
              ),
              busStop: busStopData
                ? {
                    value: busStopData.NaptanCode,
                    label: busStopData.Name,
                    Latitude: parseFloat(busStopData.Latitude),
                    Longitude: parseFloat(busStopData.Longitude),
                    Name: busStopData.Name,
                  }
                : null,
              lectures: initialData.lectures.map((lecture) => ({
                campus: campusOptions[initialData.university].find(
                  (campus) => campus.value === lecture.campus
                ),
                day: { value: lecture.day, label: lecture.day },
                time: { value: lecture.time, label: lecture.time },
              })),
            };
            setFormData(transformedData);
          } else {
            // If no bus stop data, just transform the rest of the data
            const transformedData = {
              ...initialData,
              name: initialData.name || '',
              university: universityOptions.find(
                (opt) => opt.value === initialData.university
              ),
              campuses: campusOptions[initialData.university].filter(
                (campus) => initialData.campuses.includes(campus.value)
              ),
              busStop: null,
              lectures: initialData.lectures.map((lecture) => ({
                campus: campusOptions[initialData.university].find(
                  (campus) => campus.value === lecture.campus
                ),
                day: { value: lecture.day, label: lecture.day },
                time: { value: lecture.time, label: lecture.time },
              })),
            };
            setFormData(transformedData);
          }
          // If editing, start at the lectures step
          setCurrentStep(initialData ? 3 : 1);
        } catch (error) {
          console.error('Error loading initial bus stop:', error);
          toast.error('Failed to load bus stop information');
        }
      };

      loadInitialBusStop();
    }
  }, [initialData]);

  useEffect(() => {
    if (formData.campuses && formData.campuses.length > 0) {
      const defaultCampus = formData.campuses[0];
      formData.lectures.forEach((lecture, index) => {
        if (!lecture.campus) {
          handleLectureChange(index, 'campus', defaultCampus);
        }
      });
    }
  }, [formData.campuses]);

  useEffect(() => {
    if (formData.campuses && formData.campuses.length === 1) {
      // If there's only one campus, automatically set it for all lectures
      setFormData(prev => ({
        ...prev,
        lectures: prev.lectures.map(lecture => ({
          ...lecture,
          campus: formData.campuses[0]
        }))
      }));
    }
  }, [formData.campuses]);

  const handleGetCurrentLocation = async () => {
    setLoadingLocation(true);
    setErrorMessage('');

    if (!navigator.geolocation) {
      toast.error('Geolocation is not supported by your browser');
      setLoadingLocation(false);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      handleLocationFound,
      (error) => {
        console.error('Error getting location:', error);
        toast.error('Failed to get your location. Please ensure location services are enabled.');
        setLoadingLocation(false);
      },
      { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
    );
  };

  const handleLocationFound = async (position) => {
    const { latitude, longitude } = position.coords;
    setUserLocation({ latitude, longitude });

    try {
      const response = await axiosInstance.get('/api/timetable/nearby-bus-stops', {
        params: {
          latitude,
          longitude,
          radius: 1000,
        },
      });

      if (response.data && Array.isArray(response.data)) {
        const busStopOptions = response.data.map((stop) => ({
          value: stop.NaptanCode,
          label: `${stop.Name} (${Math.round(stop.distance)}m away)`,
          Name: stop.Name,
          Latitude: Number(stop.Latitude),
          Longitude: Number(stop.Longitude),
          distance: stop.distance
        }));

        setNearbyBusStops(busStopOptions);
        
        if (busStopOptions.length > 0) {
          toast.success('Found nearby bus stops!');
        } else {
          toast.info('No bus stops found within 1km. Try a different location.');
        }
      }
    } catch (error) {
      console.error('Error fetching bus stops:', error);
      toast.error('Failed to fetch nearby bus stops. Please try again.');
    } finally {
      setLoadingLocation(false);
    }
  };

  const loadBusStopOptions = async (inputValue) => {
    if (!inputValue) {
      return [];
    }

    try {
      const response = await axiosInstance.get('/api/timetable/bus-stops', {
        params: { search: inputValue }, // Ensure the query parameter matches your backend
      });

      return response.data.map((stop) => ({
        value: stop.NaptanCode,
        label: stop.Name,
        Name: stop.Name,
        Latitude: Number(stop.Latitude),
        Longitude: Number(stop.Longitude),
      }));
    } catch (error) {
      console.error('Error loading bus stops:', error);
      return [];
    }
  };

  const handleBusStopSelect = async (selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      busStop: selectedOption,
    }));
  };

  const handleNearbyBusStopClick = (stop) => {
    setFormData((prev) => ({
      ...prev,
      busStop: stop,
    }));
  };

  const handleUniversitySelect = (selectedUniversity) => {
    if (selectedUniversity?.value === 'UOB') {
      const confirmed = window.confirm(
        'This app was created by a UWE student. Are you sure you wish to continue?'
      );
      if (!confirmed) {
        return;
      }
    }

    setFormData(prev => ({
      ...prev,
      university: selectedUniversity,
      campuses: [], // Reset campuses when university changes
      lectures: [] // Reset lectures when university changes
    }));
  };

  const handleCampusChange = (selectedOptions) => {
    setFormData((prev) => ({
      ...prev,
      campuses: selectedOptions ? (Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions]) : null,
      lectures: [], // Reset lectures when campuses change
    }));
  };

  const handleStepTransition = (newStep) => {
    setAnimatingStep(true);
    setTimeout(() => {
      setCurrentStep(newStep);
      setAnimatingStep(false);
    }, 300);
  };

  const handleNext = () => {
    if (validateCurrentStep()) {
      handleStepTransition(currentStep + 1);
    }
  };

  const handleBack = () => {
    handleStepTransition(currentStep - 1);
  };

  const validateCurrentStep = () => {
    switch (currentStep) {
      case 1:
        if (!formData.university) {
          toast.warning('Please select a university');
          return false;
        }
        return true;
      case 2:
        if (!formData.busStop || !formData.campuses || formData.campuses.length === 0) {
          toast.warning('Please select both campuses and a bus stop');
          return false;
        }
        if (!formData.walkTime || isNaN(formData.walkTime) || formData.walkTime < 0) {
          toast.warning('Please enter a valid walk time');
          return false;
        }
        return true;
      case 3:
        if (!formData.name) {
          toast.warning('Please enter a timetable name');
          return false;
        }
        return true;
      default:
        return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateCurrentStep()) return;

    try {
      // Validate that we have a campus selected
      if (!formData.campuses?.length) {
        toast.error('Please select a campus');
        return;
      }

      // Validate that we have at least one lecture
      if (!formData.lectures || formData.lectures.length === 0) {
        toast.error('Please add at least one lecture');
        return;
      }

      // Validate all lectures have required fields
      const invalidLectures = formData.lectures.filter(
        lecture => !lecture?.day?.value || !lecture?.time?.value || !lecture?.campus?.value
      );

      if (invalidLectures.length > 0) {
        toast.error('Each lecture must have a campus, day and time');
        return;
      }
      
      const formattedData = {
        name: formData.name,
        university: formData.university?.value,
        campuses: formData.campuses?.map(campus => campus.value) || [],
        busStop: formData.busStop ? {
          NaptanCode: formData.busStop.value,
          Name: formData.busStop.Name
        } : null,
        walkTime: formData.walkTime,
        lectures: formData.lectures.map(lecture => ({
          campus: lecture.campus?.value || (formData.campuses?.length === 1 ? formData.campuses[0].value : null),
          day: lecture.day.value,
          time: lecture.time.value
        }))
      };

      await onSubmit(formattedData);
      toast.success('Timetable saved successfully!');
    } catch (error) {
      console.error('Error saving timetable:', error);
      toast.error('Failed to save timetable. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddLecture = () => {
    setFormData((prev) => ({
      ...prev,
      lectures: [
        ...prev.lectures,
        {
          campus: prev.campuses?.length === 1 ? prev.campuses[0] : null,
          day: null,
          time: null,
        },
      ],
    }));
  };

  const handleRemoveLecture = (index) => {
    setFormData((prev) => ({
      ...prev,
      lectures: prev.lectures.filter((_, i) => i !== index),
    }));
  };

  const handleLectureChange = (index, field, value) => {
    setFormData((prev) => ({
      ...prev,
      lectures: prev.lectures.map((lecture, i) =>
        i === index ? { ...lecture, [field]: value } : lecture
      ),
    }));
  };

  const handleCalculateWalkTime = async () => {
    if (!userLocation || !formData.busStop) {
      toast.warning('Please select your location and a bus stop first');
      return;
    }

    try {
      console.log('Calculating walk time with:', {
        userLocation,
        busStop: formData.busStop
      });

      const walkTime = await estimateWalkingTime(
        userLocation,
        {
          latitude: formData.busStop.Latitude,
          longitude: formData.busStop.Longitude,
        }
      );

      console.log('Received walk time:', walkTime);

      if (walkTime) {
        setFormData(prev => {
          const newState = {
            ...prev,
            walkTime: walkTime.toString()
          };
          console.log('Setting new form data:', newState);
          return newState;
        });

        toast.success('Walk time calculated using OpenRouteService');
      } else {
        toast.error('Could not calculate walk time');
      }
    } catch (error) {
      console.error('Error calculating walk time:', error);
      toast.error('Failed to calculate walk time');
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="form-step">
            <div className="form-group">
              <label>Select Your University</label>
              <div className="university-selection">
                {universityOptions.map((uni) => (
                  <div
                    key={uni.value}
                    className={`university-option ${
                      formData.university?.value === uni.value ? 'selected' : ''
                    }`}
                    onClick={() => handleUniversitySelect(uni)}
                  >
                    <FaCheck className="check-icon" />
                    <img src={uni.logo} alt={`${uni.label} logo`} />
                    <div className="name">{uni.label}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="form-step">
            <div className="form-group">
              <label>Campus</label>
              <Select
                value={formData.campuses}
                onChange={handleCampusChange}
                options={formData.university ? campusOptions[formData.university.value] : []}
                className="react-select"
                classNamePrefix="react-select"
                isDisabled={!formData.university}
                placeholder="Select campuses"
                isClearable
                isMulti
              />
            </div>
            <div className="form-group">
              <label>Bus Stop</label>
              <div className="bus-stop-container">
                <AsyncSelect
                  value={formData.busStop}
                  onChange={handleBusStopSelect}
                  loadOptions={loadBusStopOptions}
                  defaultOptions={nearbyBusStops}
                  className="react-select"
                  classNamePrefix="react-select"
                  placeholder={
                    nearbyBusStops.length > 0
                      ? `${nearbyBusStops.length} nearby stops found`
                      : 'Search for a bus stop'
                  }
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                    menu: (base) => ({ ...base, zIndex: 99999 })
                  }}
                />
                <button
                  type="button"
                  className="btn location-btn"
                  onClick={handleGetCurrentLocation}
                  disabled={loadingLocation}
                >
                  <FaLocationArrow />{' '}
                  {loadingLocation ? 'Finding...' : 'Use Current Location'}
                </button>
              </div>
              {nearbyBusStops.length > 0 && (
                <div className="nearby-stops-list">
                  <div className="nearby-stops-header">
                    <p>Nearby Bus Stops:</p>
                    <button
                      type="button"
                      className="btn map-btn"
                      onClick={() => setShowMap(true)}
                    >
                      <FaMapMarkedAlt /> View on Map
                    </button>
                  </div>
                  {nearbyBusStops.map((stop, index) => (
                    <div
                      key={stop.value}
                      className={`nearby-stop-item ${
                        formData.busStop?.value === stop.value
                          ? 'selected'
                          : ''
                      }`}
                      onClick={() => {
                        handleNearbyBusStopClick(stop);
                      }}
                    >
                      {index + 1}. {stop.label}
                    </div>
                  ))}
                </div>
              )}
              {showMap && userLocation && (
                <NearbyBusStopsMap
                  busStops={nearbyBusStops}
                  userLocation={userLocation}
                  onClose={() => setShowMap(false)}
                />
              )}
            </div>
            <div className="form-group">
              <label>Walk Time (minutes)</label>
              <div className="walk-time-container">
                <input
                  type="number"
                  value={formData.walkTime}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, '');
                    setFormData((prev) => ({
                      ...prev,
                      walkTime: value,
                    }));
                  }}
                  placeholder="Enter walk time in minutes"
                  className="form-control"
                  min="0"
                />
                <button
                  type="button"
                  className="btn calculate-btn"
                  onClick={handleCalculateWalkTime}
                  disabled={!userLocation || !formData.busStop}
                >
                  <FaCalculator /> Calculate
                </button>
              </div>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="form-step">
            <div className="form-group">
              <label>Timetable Name</label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }))
                }
                placeholder="Enter timetable name"
                className="form-control"
              />
            </div>
            <div className="lectures-container">
              {formData.lectures.map((lecture, index) => (
                <div key={index} className="lecture-item">
                  <div className="lecture-header">
                    <h4>Lecture {index + 1}</h4>
                    <button
                      type="button"
                      className="remove-btn"
                      onClick={() => handleRemoveLecture(index)}
                      aria-label="Remove lecture"
                    >
                      <FaTrash />
                    </button>
                  </div>
                  <div className="lecture-content">
                    {formData.campuses?.length > 1 && (
                      <div className="form-group">
                        <label>Campus</label>
                        <Select
                          value={lecture.campus}
                          onChange={(selectedOption) =>
                            handleLectureChange(index, 'campus', selectedOption)
                          }
                          options={formData.campuses}
                          className="react-select"
                          classNamePrefix="react-select"
                          placeholder="Select campus"
                          isSearchable={false}
                        />
                      </div>
                    )}
                    <div className="form-group">
                      <label>Day</label>
                      <Select
                        value={lecture.day}
                        onChange={(selectedOption) =>
                          handleLectureChange(index, 'day', selectedOption)
                        }
                        options={dayOptions}
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Select day"
                      />
                    </div>
                    <div className="form-group">
                      <label>Time</label>
                      <Select
                        value={lecture.time}
                        onChange={(selectedOption) =>
                          handleLectureChange(index, 'time', selectedOption)
                        }
                        options={timeOptions}
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Select time"
                      />
                    </div>
                  </div>
                </div>
              ))}
              <button
                type="button"
                className="add-lecture-btn"
                onClick={handleAddLecture}
              >
                <FaPlus /> Add Lecture
              </button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <form className="timetable-form" onSubmit={handleSubmit}>
      <div className="form-header">
        <h2>{initialData ? 'Edit Timetable' : 'Create New Timetable'}</h2>
      </div>

      <div className="form-progress">
        <div className="progress-steps">
          {[1, 2, 3].map((step) => (
            <div
              key={step}
              className={`progress-step ${
                currentStep === step ? 'active' : ''
              } ${currentStep > step ? 'completed' : ''}`}
            >
              <div className="step-number">
                {currentStep > step ? (
                  <FaCheck />
                ) : (
                  step
                )}
              </div>
              <div className="step-label">
                {step === 1 ? 'University' : step === 2 ? 'Location' : 'Schedule'}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={`form-content ${animatingStep ? 'animating' : ''}`}>
        {renderStepContent()}
      </div>

      <div className="form-actions">
        {currentStep > 1 && (
          <button
            type="button"
            className="btn back-btn"
            onClick={handleBack}
            disabled={isLoading}
          >
            <FaArrowLeft /> <span>Back</span>
          </button>
        )}
        
        {currentStep < 3 ? (
          <button
            type="button"
            className="btn next-btn"
            onClick={handleNext}
            disabled={isLoading}
          >
            <span>Next</span> <FaArrowRight />
          </button>
        ) : (
          <button
            type="button"
            className="btn save-btn"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            <span>Save</span> <FaCheck />
          </button>
        )}
      </div>
    </form>
  );
};

export default TimetableForm;