import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBus, FaBell, FaUniversity, FaClock, FaMobile, FaRoute, FaInstagram, FaEnvelope } from 'react-icons/fa';
import './styles/ModernLandingPage.css';

function ModernLandingPage() {
  const navigate = useNavigate();
  
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
        }
      });
    }, observerOptions);

    document.querySelectorAll('.animate-on-scroll').forEach((element) => {
      observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="modern-landing">
      {/* Top Navigation */}
      <nav className="top-nav">
        <div className="nav-auth">
          <button onClick={() => navigate('/login')} className="nav-button">Sign in</button>
          <span className="nav-divider">/</span>
          <button onClick={() => navigate('/register')} className="nav-button">Register</button>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Two-Stopper</h1>
          <p className="hero-subtitle">Your Smart Bus Companion</p>
          <div className="feature-grid">
            <div className="feature-item">
              <FaRoute className="feature-icon" />
              <h3>Smart Routes</h3>
              <p>AI-optimised bus routes for your schedule</p>
            </div>
            <div className="feature-item">
              <FaBell className="feature-icon" />
              <h3>Timely Alerts</h3>
              <p>Get notified when your bus is approaching</p>
            </div>
            <div className="feature-item">
              <FaClock className="feature-icon" />
              <h3>Real-Time</h3>
              <p>Live bus tracking and arrival times</p>
            </div>
            <div className="feature-item">
              <FaMobile className="feature-icon" />
              <h3>SMS Updates</h3>
              <p>Text notifications for your journey</p>
            </div>
          </div>
        </div>
      </section>

      {/* Universities Section */}
      <section className="universities-section">
        <div className="universities-content">
          <div className="universities-text">
            <h2>Free for Bristol Students</h2>
            <p className="supporting-text">Currently Supporting</p>
            <div className="university-cards">
              <img src="/uwe-logo.svg" alt="UWE Bristol" className="uni-logo" />
              <img src="/uob-logo.png" alt="University of Bristol" className="uni-logo" />
            </div>
          </div>
          <div className="cta-section">
            <h3>Never Miss a Lecture Again</h3>
            <div className="cta-buttons">
              <button onClick={() => navigate('/register')} className="cta-button primary">
                Get Started
              </button>
              <button onClick={() => navigate('/login')} className="cta-button secondary">
                Sign In
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="modern-footer">
        <div className="footer-content">
          <div className="footer-section">
            <h4>Contact Us</h4>
            <div className="contact-info">
              <a href="mailto:contact@twostopper.co.uk" className="footer-link">
                <FaEnvelope className="footer-icon" />
                contact@twostopper.co.uk
              </a>
            </div>
          </div>
          <div className="footer-section">
            <h4>Follow Us</h4>
            <div className="social-links">
              <a href="https://instagram.com/twostopper" target="_blank" rel="noopener noreferrer" className="footer-link">
                <FaInstagram className="footer-icon" />
                @twostopper
              </a>
            </div>
          </div>
          <div className="footer-section">
            <h4>Legal</h4>
            <div className="legal-links">
              <a href="/privacy" className="footer-link">Privacy Policy</a>
              <a href="/terms" className="footer-link">Terms of Service</a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} Two-Stopper. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default ModernLandingPage;
