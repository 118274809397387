import React, { createContext, useState, useContext } from 'react';

const SidebarContext = createContext();

export function SidebarProvider({ children }) {
  const [shouldAnimateSidebar, setShouldAnimateSidebar] = useState(true);

  const resetAnimation = () => {
    setShouldAnimateSidebar(true);
  };

  const disableAnimation = () => {
    setShouldAnimateSidebar(false);
  };

  return (
    <SidebarContext.Provider value={{ shouldAnimateSidebar, resetAnimation, disableAnimation }}>
      {children}
    </SidebarContext.Provider>
  );
}

export function useSidebar() {
  return useContext(SidebarContext);
}
