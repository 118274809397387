import React, { useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './NearbyBusStopsMap.css';

const NearbyBusStopsMap = ({ busStops, userLocation, onClose }) => {
  useEffect(() => {
    // Create map instance
    const map = L.map('nearby-bus-stops-map').setView(
      [userLocation.latitude, userLocation.longitude],
      15
    );

    // Add OpenStreetMap tiles
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© OpenStreetMap contributors'
    }).addTo(map);

    // Add user location marker
    L.marker([userLocation.latitude, userLocation.longitude], {
      icon: L.divIcon({
        className: 'user-location-marker',
        html: '📍',
        iconSize: [25, 25],
        iconAnchor: [12, 24]
      })
    })
      .addTo(map)
      .bindPopup('Your Location');

    // Add bus stop markers
    busStops.forEach((stop) => {
      L.marker([stop.Latitude, stop.Longitude], {
        icon: L.divIcon({
          className: 'bus-stop-marker',
          html: '🚌',
          iconSize: [25, 25],
          iconAnchor: [12, 24]
        })
      })
        .addTo(map)
        .bindPopup(`${stop.Name}<br>Distance: ${stop.distance}m`);
    });

    // Cleanup on unmount
    return () => {
      map.remove();
    };
  }, [busStops, userLocation]);

  return (
    <div className="map-overlay">
      <div className="map-container">
        <button className="close-map-btn" onClick={onClose}>×</button>
        <div id="nearby-bus-stops-map" />
      </div>
    </div>
  );
};

export default NearbyBusStopsMap;
