import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log('404 triggered for path:', location.pathname);
    
    // Check if this is a page refresh
    const isPageRefresh = !sessionStorage.getItem('app_initialized');
    if (isPageRefresh) {
      console.log('Page refresh detected, setting app_initialized');
      sessionStorage.setItem('app_initialized', 'true');
      
      // Redirect to root URL based on environment
      const isProd = window.location.hostname === 'twostopper.co.uk';
      const rootUrl = isProd ? 'https://twostopper.co.uk' : 'http://localhost:3000';
      window.location.href = rootUrl;
    } else {
      // For actual 404s, redirect to root after a short delay
      setTimeout(() => {
        console.log('Redirecting to root...');
        navigate('/', { replace: true });
      }, 100);
    }
  }, [navigate, location]);

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h1>Redirecting...</h1>
    </div>
  );
};

export default NotFound;
