import React, { createContext, useState, useEffect, useCallback } from 'react';
import axiosInstance from '../axiosConfig';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ isAuthenticated: false, loading: true, user: null });
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);

  // Function to check authentication status
  const checkAuth = useCallback(async () => {
    if (isUpdatingProfile) return; // Skip if we're currently updating the profile
    
    console.log('AuthContext: Running checkAuth...');
    const token = localStorage.getItem('token');
    
    if (!token) {
      console.log('AuthContext: No token found');
      setAuth({ isAuthenticated: false, loading: false, user: null });
      return;
    }

    try {
      const res = await axiosInstance.get('/api/user/profile');
      console.log('AuthContext: Authentication successful:', res.data);
      
      // Only update if not currently updating profile
      if (!isUpdatingProfile) {
        setAuth({ isAuthenticated: true, loading: false, user: res.data });
      }
    } catch (err) {
      console.error('AuthContext: Authentication check failed:', err.response?.data || err);
      if (err.response?.status === 401) {
        localStorage.removeItem('token');
      }
      setAuth({ isAuthenticated: false, loading: false, user: null });
    }
  }, [isUpdatingProfile]);

  // Function to log out the user
  const logout = async () => {
    console.log('AuthContext: Logging out...');
    try {
      await axiosInstance.post('/api/auth/logout');
      console.log('AuthContext: Logout successful.');
    } catch (err) {
      console.error('AuthContext: Logout failed:', err.response?.data || err);
    } finally {
      localStorage.removeItem('token');
      setAuth({ isAuthenticated: false, loading: false, user: null });
    }
  };

  // Function to toggle notifications
  const toggleNotifications = useCallback(async () => {
    if (!auth.user || isUpdatingProfile) return false;
    
    try {
      setIsUpdatingProfile(true);
      const newNotificationState = !auth.user.notifications;
      
      // Make API call to update user preferences
      const response = await axiosInstance.put('/api/user/profile', {
        notifications: newNotificationState
      });

      if (!response.data) {
        throw new Error('No response data received');
      }

      // Update the auth context with the response data
      setAuth(prev => ({
        ...prev,
        user: response.data
      }));
      
      setIsUpdatingProfile(false);
      return true;
    } catch (error) {
      console.error('Failed to toggle notifications:', error);
      setIsUpdatingProfile(false);
      return false;
    }
  }, [auth.user, isUpdatingProfile]);

  useEffect(() => {
    console.log('AuthContext: Initializing authentication check...');
    checkAuth();
  }, [checkAuth]);

  return (
    <AuthContext.Provider value={{
      isAuthenticated: auth.isAuthenticated,
      loading: auth.loading,
      user: auth.user,
      toggleNotifications,
      logout,
      checkAuth
    }}>
      {children}
    </AuthContext.Provider>
  );
};