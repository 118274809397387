// src/EditProfile.js

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';
import axiosInstance from './axiosConfig';
import Sidebar from './components/Sidebar';
import { toast, ToastContainer } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/EditProfile.css';

function EditProfile() {
  const navigate = useNavigate();
  const { user, checkAuth } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    name: user?.name || '',
    email: user?.email || '',
    phone: user?.phone || ''  // Keep the full number including +44
  });
  const [isLoading, setIsLoading] = useState(false);
  const [phoneValid, setPhoneValid] = useState(true);

  const handleChange = (e) => {
    if (typeof e === 'string') {
      // This is from the phone input
      // Ensure the number starts with 44 (country code)
      const phoneNumber = e.startsWith('44') ? e : `44${e}`;
      setFormData(prev => ({
        ...prev,
        phone: phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`
      }));
      
      // Validate UK mobile number (should start with 7 after country code)
      const numberAfterCode = phoneNumber.replace(/^\+?44/, '');
      setPhoneValid(numberAfterCode.startsWith('7'));
    } else {
      setFormData(prev => ({
        ...prev,
        [e.target.name]: e.target.value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!phoneValid) {
      toast.error('Please enter a valid UK mobile number starting with 07');
      return;
    }
    
    setIsLoading(true);

    try {
      const updatedData = {
        ...formData,
        phone: formData.phone // No need to modify as we're already storing with +44
      };

      await axiosInstance.put('/api/user/profile', updatedData);
      await checkAuth();
      toast.success('Profile updated successfully');
      navigate('/profile');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error(error.response?.data?.msg || 'Failed to update profile');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate('/profile');
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="layout">
      <Sidebar />
      <div className="main-content">
        <ToastContainer position="top-right" autoClose={3000} />
        <div className="edit-profile-container">
          <div className="edit-profile-form">
            <div className="edit-profile-header">
              <h1>Edit Profile</h1>
              <p className="header-subtitle">Update your personal information</p>
            </div>
            
            <div className="form-content">
              <form onSubmit={handleSubmit}>
                <div className="form-section">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      autoComplete="name"
                      className="apple-input"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      disabled
                      className="apple-input disabled-input"
                    />
                    <span className="input-note">Email cannot be changed</span>
                  </div>

                  <div className="form-group">
                    <label htmlFor="phone">Phone</label>
                    <PhoneInput
                      country={'gb'}
                      onlyCountries={['gb']}
                      countryCodeEditable={false}
                      value={formData.phone}
                      onChange={handleChange}
                      inputProps={{
                        id: 'phone',
                        name: 'phone',
                        required: true,
                      }}
                      containerClass="phone-input-container"
                      inputClass={`apple-input ${!phoneValid ? 'invalid' : ''}`}
                      buttonClass="phone-button"
                      dropdownClass="phone-dropdown"
                      prefix="+"
                      placeholder="447XXXXXXXXX"
                    />
                    {!phoneValid && (
                      <span className="error-message">Please enter a valid UK mobile number starting with 07</span>
                    )}
                    <span className="input-note">UK mobile number (starts with +44)</span>
                  </div>
                </div>

                <div className="button-group">
                  <button 
                    type="button" 
                    className="apple-button cancel-button" 
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button 
                    type="submit" 
                    className="apple-button save-button" 
                    disabled={isLoading}
                  >
                    {isLoading ? 'Saving...' : 'Save Changes'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
