// src/Timetable.js

import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';
import { useNavigate } from 'react-router-dom';
import './Timetable.css';
import { 
  HiPencil as EditIcon,
  HiTrash as DeleteIcon,
  HiStar as StarIcon,
  HiOutlineStar as StarOutlineIcon,
  HiPlus as PlusIcon,
  HiCalendar as CalendarIcon,
  HiLocationMarker as LocationIcon
} from 'react-icons/hi';
import { IoAdd, IoList } from 'react-icons/io5';
import { toast } from 'react-toastify';
import Sidebar from './components/Sidebar';

function Timetable() {
  const [timetables, setTimetables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTimetables = async () => {
      try {
        const res = await axiosInstance.get('/api/timetable');
        setTimetables(res.data.timetables);
        setError(null);
      } catch (err) {
        console.error('Error fetching timetables:', err);
        if (err.response?.status !== 404) {
          setError('Something went wrong. Please try again later.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTimetables();
  }, []);

  const handleEdit = (id) => {
    navigate(`/timetable/edit/${id}`);
  };

  const handleDelete = async (id) => {
    const timetable = timetables.find(tt => tt._id === id);
    if (window.confirm(`Are you sure you want to delete timetable "${timetable?.name || 'Untitled'}"? This action cannot be undone.`)) {
      try {
        await axiosInstance.delete(`/api/timetable/${id}`);
        setTimetables(timetables.filter((tt) => tt._id !== id));
        toast.success(`Timetable "${timetable?.name || 'Untitled'}" deleted successfully`);
      } catch (err) {
        const errorMsg = err.response?.data?.msg || 'Failed to delete timetable';
        toast.error(errorMsg);
        setError(errorMsg);
      }
    }
  };

  const handleSetCurrent = async (id) => {
    const timetable = timetables.find(tt => tt._id === id);
    try {
      const response = await axiosInstance.post(`/api/timetable/${id}/set-current`);
      
      if (response.data.success) {
        setTimetables(prevTimetables => 
          prevTimetables.map(tt => ({
            ...tt,
            isCurrent: tt._id === id
          }))
        );
        toast.success(`"${timetable?.name || 'Untitled'}" set as current timetable`);
        setError('');
      } else {
        throw new Error(response.data.msg || 'Failed to set timetable as current');
      }
    } catch (error) {
      console.error('Error setting current timetable:', error);
      const errorMsg = error.response?.data?.msg || 'Failed to set timetable as current';
      toast.error(errorMsg);
      setError(errorMsg);
    }
  };

  const isToday = (day) => {
    const today = new Date();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[today.getDay()] === day;
  };

  const getNextLecture = (lectures) => {
    if (!lectures || lectures.length === 0) return null;
    
    const today = new Date();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const currentDay = days[today.getDay()];
    const currentTime = today.getHours() * 60 + today.getMinutes();

    // First, check today's remaining lectures
    const todayLectures = lectures.filter(lecture => lecture.day === currentDay);
    for (const lecture of todayLectures) {
      const [hours, minutes] = lecture.time.split(':').map(Number);
      const lectureTime = hours * 60 + minutes;
      if (lectureTime > currentTime) {
        return lecture;
      }
    }

    // If no lectures remaining today, find the next day with lectures
    let dayIndex = (today.getDay() + 1) % 7;
    for (let i = 0; i < 7; i++) {
      const nextDay = days[dayIndex];
      const nextDayLectures = lectures.filter(lecture => lecture.day === nextDay);
      if (nextDayLectures.length > 0) {
        return nextDayLectures[0];
      }
      dayIndex = (dayIndex + 1) % 7;
    }

    return lectures[0]; // If no next lecture found, return the first lecture
  };

  if (loading) {
    return (
      <div className="home-container">
        <Sidebar />
        <main className="main-content loading">
          <div className="content-wrapper">
            <div className="glass-card shimmer-placeholder header" />
            <div className="glass-card shimmer-placeholder card" />
          </div>
        </main>
      </div>
    );
  }

  if (!timetables.length) {
    return (
      <div className="home-container">
        <Sidebar />
        <div className="main-content">
          <div className="welcome-container">
            <h1>Create Your First Timetable</h1>
            <p>Start by creating a timetable to manage your university schedule and bus times.</p>
            <div className="welcome-buttons">
              <button 
                className="apple-button primary"
                onClick={() => navigate('/timetable/add')}
              >
                <IoAdd className="button-icon" />
                New Timetable
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="home-container">
      <Sidebar />
      <div className="main-content">
        <div className="content">
          <div className="section">
            <div className="timetables-container">
              {error ? (
                <p className="error-message">{error}</p>
              ) : (
                <>
                  <button className="icon-button add-button fade-in-item" onClick={() => navigate('/timetable/add', { state: { from: 'timetable' } })}>
                    <PlusIcon />
                  </button>
                  <div className="timetables-list">
                    {timetables.map((timetable, timetableIndex) => {
                      const nextLecture = getNextLecture(timetable.lectures);
                      return (
                        <div key={timetable._id} className={`timetable-tile fade-in-item delay-${timetableIndex}`}>
                          <div className="tile-header">
                            <h3>{timetable.name || 'Untitled Timetable'}</h3>
                            {timetable.isCurrent && (
                              <div className="current-indicator">
                                Current
                              </div>
                            )}
                          </div>
                          
                          <div className="tile-content">
                            <div className="university-info">
                              <span>{timetable.university}</span>
                              <span className="campus-info">{timetable.campuses.join(' · ')}</span>
                            </div>

                            {nextLecture && (
                              <div className="next-lecture">
                                <h4>Next Lecture</h4>
                                <div className={`schedule-item ${isToday(nextLecture.day) ? 'today' : ''}`}>
                                  <div className="time-info">
                                    <span className="day">{nextLecture.day}</span>
                                    <span className="time">{nextLecture.time}</span>
                                  </div>
                                  <span className="campus">{nextLecture.campus}</span>
                                </div>
                              </div>
                            )}

                            <div className="schedule-list">
                              {timetable.lectures.map((lecture, index) => (
                                <div 
                                  key={index} 
                                  className={`schedule-item fade-in-item delay-${index + 3} ${isToday(lecture.day) ? 'today' : ''}`}
                                >
                                  <div className="time-info">
                                    <span className="day">{lecture.day}</span>
                                    <span className="time">{lecture.time}</span>
                                  </div>
                                  <span className="campus">{lecture.campus}</span>
                                </div>
                              ))}
                            </div>

                            <div className="tile-footer">
                              <div className="stats">
                                <div className="stat-item">
                                  <CalendarIcon />
                                  <span>
                                    {timetable.lectures.length} {timetable.lectures.length === 1 ? 'lecture' : 'lectures'}
                                  </span>
                                </div>
                                <div className="stat-item">
                                  <LocationIcon />
                                  <span>
                                    {timetable.campuses.length} {timetable.campuses.length === 1 ? 'campus' : 'campuses'}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="tile-actions">
                              <button onClick={() => handleEdit(timetable._id)} className="action-btn edit" aria-label="Edit timetable">
                                <EditIcon />
                              </button>
                              <button 
                                onClick={() => !timetable.isCurrent && handleSetCurrent(timetable._id)}
                                disabled={timetable.isCurrent}
                                className={`action-btn star ${timetable.isCurrent ? 'active' : ''}`}
                                aria-label={timetable.isCurrent ? "Current timetable" : "Set as current timetable"}
                              >
                                {timetable.isCurrent ? <StarIcon /> : <StarOutlineIcon />}
                              </button>
                              <button onClick={() => handleDelete(timetable._id)} className="action-btn delete" aria-label="Delete timetable">
                                <DeleteIcon />
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timetable;